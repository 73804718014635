import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog33.jpg'
import styles from './post-grid.module.css'

const RightSnacks = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jaké svačiny jsou pro vás vhodné?'}
        description={
          'Svačiny mohou v našem jídelníčku hrát zásadní roli. Není sice vždy nutné, abychom je do jídelníčku zahrnuli, ale může to významně pomoci. '
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/right-snacks">
          Jaké svačiny jsou pro vás vhodné?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Jaké svačiny jsou pro vás vhodné?" date="12.09.23" />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>
                Svačiny mohou v našem jídelníčku hrát
                <b> zásadní roli. </b>
                Není sice vždy nutné, abychom je do jídelníčku zahrnuli, ale
                může to
                <b> významně pomoci.</b> Někomu může dělat problém dodržet
                optimální denní energetický příjem pouze ve 3 (a méně) jídlech,
                nebo můžete mít mezi hlavními jídly
                <b> hlad či chutě.</b> A přesně od toho tu jsou. I u svačin je
                však důležité dbát na to,<b> aby byly kvalitní.</b>
              </p>

              <p className={styles.postText}>
                Může se stát, že někam
                <b> pospícháte, </b>nebo jste si doma svačinu zapomněli. Vběhnete
                proto do nějakého fastfoodu či kafetérie a koupíte si to první,
                co vidíte. Může se však jednat o<b> polotovary </b>nebo jídla,
                která jsou plná<b> tuku a cukru.</b> Vy ale nestíháte, a tak se
                prostě a jednoduše potřebujete jen
                <b> něčeho najíst.</b> To ale povede k tomu, že budete mít{' '}
                <b>brzy zase hlad,</b> a takhle se budete točit.
              </p>

              <p className={styles.postText}>
                To, abychom <b> jedli kvalitně i mezi hlavními jídly,</b> se může
                zdát nedůležité, protože se jedná o malé svačinky. I malé
                svačinky mohou však obsahovat spoustu kalorií a rozhodit nám
                denní režim.
                <b> A jak by tedy měly takové vhodné svačiny vypadat?</b>
              </p>

              <p className={styles.postText}>
                Svačiny by, stejně jako každé jiné jídlo, měly obsahovat{' '}
                <b>ideálně všechny makroživiny</b>, tedy sacharidy, bílkoviny a
                tuky. Za mě je
                <b> vzornou a jednoduchou </b>svačinou například kousek ovoce či
                zeleniny, mléčný výrobek (neochucený), pečivo a ořechy nebo
                semínka. Může to být konkrétně bílý jogurt s banánem a chia
                semínky nebo taky žitný chléb s žervé, šunkou, sýrem a rajčaty.
              </p>

              <p className={styles.postText}>
                Samozřejmě se <b> nemusíte bičovat,</b> pokud vaše svačina
                neobsahuje úplně vše, pořád jde o
                <b> celkovou skladbu jídelníčku.</b> Také ja jasné, že si sem tam
                chcete dát něco dobrého, aniž byste museli řešit živiny a
                kalorie. To je v pořádku. Nicméně pořád byste měli myslet na
                <b> dostatek bílkovin a vlákniny</b>, které v jsou jídelníčku
                mnohdy nedostatkové, a snažit se jejich optimální denní příjem
                dodržovat.
              </p>

              <p className={styles.postText}>
                Jako příklady vhodných svačin jsem si pro vás vybrala také
                několik svačinek, které si můžete užívat v případě, že si
                zvolíte {' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky
                </a>
                .<b> Slané varianty</b> svačin může být například
                <b> toast s omáčkou guacamole, krůtí šunkou, tofu a rajčaty. </b>
                 Vidíte, že jsou zde <b>zastoupeny sacharidy</b> v podobě toastu,
                <b> bílkoviny</b> v podobě šunky a tofu, <b>tuky</b> se skrývají
                v avokádu a máme zde i zeleninu.
                <b> Tedy kompletní jídlo plné všech živin, </b>co potřebujeme.
                Další slanou variantou je{' '}
                <b>
                  quesadilla s mexickými fazolemi a sýrem, římským salátem,
                  zakysanou smetanou a koriandrem.
                </b>{' '}
                Zde máme opět zastoupeny <b>všechny potřebné živiny.</b>
              </p>

              <p className={styles.postText}>
                <b>Sladkou variantou </b> svačinek může být třeba{' '}
                <b>
                  smoothie z lesního ovoce, burákového másla a nízkotučného
                  jogurtu
                </b>{' '}
                nebo také tvarohový <b>koláč </b>s lesními plody.
              </p>

              <p className={styles.postText}>
                Na těchto příkladem můžete vidět, že{' '}
                <b> i svačiny mohou být opravdu pestré</b> a plné všeho, co naše
                tělo potřebuje. Nemluvě o tom, jak jsou <b> dobré!</b>
              </p>
              <p className={styles.postText}>
                Jestli si tyto rozmanité svačiny chcete vychutnávat i vy, a to
                bez práce, vyzkoušejte{' '}
                <a href="https://nutritionpro.cz/" target="_blank">naše krabičky</a>.{' '}Uvidíte sami, že chutě zmizí a vy budete plní energie.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default RightSnacks
